<template>
  <div>
    <el-drawer
      title="上传视频"
      size="1200px"
      append-to-body
      :visible="selectYoutubeVideoShow"
      @close="close"
      @opened="openInit"
    >
      <div class="flex flex-col h-full youtubeVideos">
        <el-form
          :model="accountParams"
          label-width="100px"
          :inline="true"
          class="youtubeForm"
        >
          <YouTubeAccount
            ref="youtubeAccount"
            v-model="accountParams"
            @change="changeChannel"
          />
        </el-form>
        <div class="p-2 flex-1 pb-[50px] box-border flex-col flex h-[95%]">
          <div class="h-full overflow-auto">
            <el-form>
              <el-form-item label="">
                <el-checkbox-group
                  v-model="checkList"
                  class="flex-1 overflow-hidden"
                  v-if="videoList.length"
                >
                  <el-checkbox
                    v-for="(video, index) in videoList"
                    :key="video.id"
                    :label="video.id"
                    class="m-1 relative w-[190px] h-[190px] p-1 border overflow-hidden videoItem inline-block mr-3"
                  >
                    <!-- youtube 视频展示 -->
                    <youtube
                      :video-id="video.videoId"
                      v-bind="params"
                      class="w-full h-full"
                    ></youtube>
                  </el-checkbox>
                </el-checkbox-group>
                <el-empty
                  v-else
                  description="暂无数据，请先选择频道"
                  class="h-full"
                ></el-empty>
              </el-form-item>
            </el-form>
          </div>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            class="text-right"
            :current-page="pageMsg.page"
            :page-sizes="[20, 50, 100]"
            :page-size="pageMsg.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="absolute right-3 bottom-5">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import YouTubeAccount from '@/views/google/components/YouTubeAccount/index.vue';
import { videoPage } from '@/api/google/youtube';
export default {
  components: { YouTubeAccount },
  props: {
    //弹窗的显示隐藏
    selectYoutubeVideoShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      accountParams: {
        channelId: '',
      },
      videoList: [],
      checkList: [],
      params: {
        'player-width': 190,
        'player-height': 190,
      },
      total: 0,
      pageMsg: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    openInit() {
      this.checkList = [];
      this.$refs.youtubeAccount.getChannel();
    },
    /**
     * 选择频道
     */
    changeChannel(v) {
      this.getList();
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.accountParams.channelId = null;
      this.videoList = [];
      this.$emit('update:selectYoutubeVideoShow', false);
    },
    /**
     * 切换一页的条数
     */
    handleSizeChange(v) {
      this.pageMsg.pageSize = v;
      this.getList();
    },
    /**
     * 切换页数
     */
    handleCurrentChange(v) {
      this.pageMsg.page = v;
      this.getList();
    },
    /**
     * 获取视频列表
     */
    getList() {
      if (!this.accountParams.channelId) {
        return;
      }
      this.$showLoading();
      videoPage({ youtubeChannelId: this.accountParams.channelId, ...this.pageMsg }).then((res) => {
        this.$hideLoading();
        this.videoList = res.data.list;
        this.total = res.data.total;
      });
    },
    /**
     * 点击确定
     */
    sure() {
      const list = this.videoList.filter((v) => this.checkList.includes(v.id));
      this.$emit('updateValue', list);
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  position: relative;
  padding: 20px;
}
::v-deep .videoItem {
  .el-checkbox {
    margin-right: 0;
  }
  .el-checkbox__input {
    position: absolute;
  }
  .el-checkbox__label {
    height: 190px;
    padding-left: 0;
  }
}
</style>
<style lang="scss">
.youtubeVideos .el-input__inner {
  border-color: #dcdfe6 !important; /* 取消红色边框 */
  box-shadow: none !important; /* 取消红色阴影 */
}
</style>
