<template>
  <span>
    <!-- <el-form-item label="youtube 账号">
      <el-select
        v-model="accountParams.accountId"
        @change="selectAccount"
        placeholder="youtube 账号"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item
      label="频道"
      label-width="80px"
      class="mb-0"
    >
      <el-select
        v-model="accountParams.channelId"
        placeholder="youtube 频道"
        :disabled="disabled"
        @change="selectChannel"
        :loading="loading"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </span>
</template>
<script>
import { getChannelList } from '@/api/google/youtube';
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          channelId: 0,
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  //   watch: {
  //     value {
  //       this. = { ...this.value };
  //     },
  //   },
  data() {
    return {
      options: [],
      loading: false,
    };
  },
  computed: {
    accountParams: {
      get() {
        return { ...this.value };
      },
      set() {},
    },
  },
  methods: {
    /**
     * 选择youtube账号
     */
    selectAccount(v) {},
    /**
     * 选择频道
     */
    selectChannel(v) {
      this.emitValue();
      this.$emit('change', v);
    },
    /**
     * 对外抛出数据
     */
    emitValue() {
      this.$emit('input', this.accountParams);
    },
    // 获取下拉数据
    getChannel() {
      this.loading = true;
      getChannelList().then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.options = res.data;
        }
      });
    },
  },
  created() {
    // this.getChannel();
  },
};
</script>
<style lang="scss" scoped></style>
